import React from "react";
import { motion } from "framer-motion";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import Card from "../common/Card";
import featured from "../../assert/baskets/basket2.jpg";
import featured1 from "../../assert/spears/shieldfinal3.jpg";
import featured3 from "../../assert/necklace/necklace1.jpg";
import { useDarkModeContext } from "../../context/DarkModeContext";
import basket1 from "../../assert/baskets/basket1.jpg";
import basket2 from "../../assert/baskets/basket2.jpg";
import basket3 from "../../assert/baskets/basket3.jpg";
import basket4 from "../../assert/baskets/basket4.jpg";
import necklace1 from "../../assert/necklace/necklace1.jpg";

const FeaturedItems = () => {
  const { ref, controls } = useScrollAnimation();
  const { isDarkMode } = useDarkModeContext();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const items = [
    {
      id: 1,
      name: "Maasai Shield",
      image: featured1,
      images: [featured1],
      culturalValue: "Very High",
      timeFrame: "19th Century",
      description:
        "Authentic Maasai shield, hand-crafted using traditional techniques.",
      origin: "Kenya, Maasai Mara region",
      materials: ["Cowhide", "Wood", "Natural pigments"],
      culturalContext:
        "Maasai shields are not just protective gear, but also symbols of status and identity. Traditionally used in warfare and lion hunting, these shields now serve as important cultural artifacts. The designs and colors on a shield can indicate the age-set and rank of its owner within Maasai society. The creation of these shields involves skilled craftsmanship passed down through generations, using locally sourced materials like cowhide and acacia wood.",
    },
    {
      id: 2,
      name: "Kikuyu Basket",
      image: featured,
      images: [featured, basket1, basket2, basket3, basket4],
      culturalValue: "High",
      timeFrame: "Early 20th Century",
      description:
        "Traditional Kikuyu basket, meticulously woven using ancient weaving techniques.",
      origin: "Kenya, Central region",
      materials: ["Sisal fibers", "Natural dyes", "Banana fiber"],
      culturalContext:
        "Kikuyu baskets represent more than just storage containers; they are a testament to the community's agricultural heritage and artistic expression. These baskets were traditionally used for storing grains and carrying harvested crops. The intricate patterns woven into each basket tell stories of family traditions and community values. The art of basket weaving is passed down through generations of Kikuyu women, serving as both a practical skill and a means of preserving cultural identity.",
    },
    {
      id: 3,
      name: " Necklaces Collection",
      image: featured3,
      images: [featured3, necklace1],
      culturalValue: "Medium",
      timeFrame: "Mid 20th Century",
      description:
        "Distinctive Kamba beaded necklace, showcasing traditional beadwork patterns.",
      origin: "Kenya, Eastern region",
      materials: ["Glass beads", "Cotton thread", "Copper clasps"],
      culturalContext:
        "Kamba beadwork is renowned for its intricate designs and vibrant color combinations. These necklaces were traditionally worn during ceremonies and special occasions, with different patterns and colors holding specific meanings. The beadwork tradition among the Kamba people reflects their artistic sophistication and trading history, as many of the glass beads were obtained through trade routes. Today, these necklaces serve as important markers of Kamba cultural heritage and artistic excellence.",
    },
    // Add more items as needed
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  return (
    <section
      ref={ref}
      className={`py-16  ${isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"}`}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          variants={titleVariants}
        >
          Featured Items
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial="hidden"
          animate={controls}
          variants={containerVariants}
        >
          {items.map((item) => (
            <motion.div key={item.id} variants={itemVariants}>
              <Card
                key={item.id}
                item={item}
                handleScrollToTop={handleScrollToTop}
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default FeaturedItems;
