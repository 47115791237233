import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { DarkModeProvider } from "./context/DarkModeContext";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import ItemPage from "./pages/ItemPage";
import CategoriesPage from "./pages/Categories";
import { MaskPage } from "./pages/MaskCategory";
import { SculpturesPage } from "./pages/Sculptures";
import { JewelryPage } from "./pages/Jewelries";
import { TextilesPage } from "./pages/Textiles";
import { BatikPage } from "./pages/Baticks";
import { Decorations } from "./pages/decorations";

function App() {
  return (
    <DarkModeProvider>
      <Router>
        <Layout>
          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/item/:id" element={<ItemPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/categories">
                <Route index element={<CategoriesPage />} />
                <Route path="masks" element={<MaskPage />} />
                <Route path="sculptures" element={<SculpturesPage />} />
                <Route path="jewelry" element={<JewelryPage />} />
                <Route path="textiles" element={<TextilesPage />} />
                <Route path="batiks" element={<BatikPage />} />
                <Route path="decorations" element={<Decorations />} />
              </Route>
            </Routes>
          </AnimatePresence>
        </Layout>
      </Router>
    </DarkModeProvider>
  );
}

export default App;
