import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useImageZoom } from "../../hooks/useImageZoom";
import { useDarkModeContext } from "../../context/DarkModeContext";

const ItemDetails = ({ item }) => {
  const [mainImage, setMainImage] = useState();
  const { zoomRef, handleMouseMove, handleMouseLeave } = useImageZoom();
  const { isDarkMode } = useDarkModeContext();

  useEffect(() => {
    setMainImage(item.images[0]);
  }, [item]);
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 mb-8 lg:mb-0">
        <motion.div
          className="relative overflow-hidden rounded-lg shadow-lg"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          <img
            ref={zoomRef}
            src={mainImage}
            alt={item.name}
            loading="lazy"
            className="w-full h-auto"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </motion.div>
        <div className="flex mt-4 flex-wrap gap-2 items-center">
          {item.images.map((image, index) => (
            <motion.img
              key={index}
              src={image}
              loading="lazy"
              alt={`${item.name} view ${index + 1}`}
              className="w-20 h-20 object-cover rounded-md cursor-pointer"
              whileHover={{ scale: 1.1 }}
              onClick={() => setMainImage(image)}
            />
          ))}
        </div>
      </div>
      <motion.div
        className="lg:w-1/2 lg:pl-8"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <h1
          className={`text-3xl font-bold mb-4 ${isDarkMode ? "text-white" : "text-gray-900"}`}
        >
          {item.name}
        </h1>
        <p className="text-2xl font-semibold mb-4 text-primary-500">
          Period: {item.timeFrame}
        </p>
        <p
          className={`text-lg mb-6 ${isDarkMode ? "text-gray-300" : "text-gray-700"}`}
        >
          {item.description}
        </p>
        <div className="mb-6">
          <h2
            className={`text-xl font-semibold mb-2 ${isDarkMode ? "text-white" : "text-gray-900"}`}
          >
            Origin
          </h2>
          <p className={isDarkMode ? "text-gray-300" : "text-gray-700"}>
            {item.origin}
          </p>
        </div>
        <div className="mb-6">
          <h2
            className={`text-xl font-semibold mb-2 ${isDarkMode ? "text-white" : "text-gray-900"}`}
          >
            Materials
          </h2>
          <p className={isDarkMode ? "text-gray-300" : "text-gray-700"}>
            {item.materials.join(", ")}
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default ItemDetails;
