import { useEffect } from "react";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const useScrollAnimation = (threshold = 0.1) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold,
    triggerOnce: true, // Only trigger once when it comes into view
  });

  useEffect(() => {
    const animateElement = async () => {
      if (inView) {
        await controls.start("show");
      } else {
        await controls.start("hidden");
      }
    };

    animateElement();
  }, [controls, inView]);

  return { ref, controls };
};
