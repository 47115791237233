import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import massaiMark from "../assert/marks/mask1.jpg";
import textileCat from "../assert/textiles&arts/textile1.jpg";
import jewelryCat from "../assert/jewelries/jewelry1.jpg";
import curioshop4 from "../assert/sculptures/sculp12.jpg";
import batick1 from "../assert/batiks/batik1.jpg";
import decoration from "../assert/decorations/decoratiosn1.jpg";
import SEOMetadata from "../components/SEOHelment";
const CategoriesPage = () => {
  const navigate = useNavigate();

  const categories = [
    {
      name: "Masks",
      description: "Traditional ceremonial masks from various cultures",
      image: massaiMark,
      count: 9,
    },
    {
      name: "Textiles",
      description: "Hand-woven fabrics and traditional garments and arts",
      image: textileCat,
      count: 17,
    },
    {
      name: "Jewelry",
      description: "Authentic handcrafted ornamental pieces",
      image: jewelryCat,
      count: 17,
    },
    {
      name: "Sculptures",
      description: "Carved artifacts and ceremonial figures",
      image: curioshop4,
      count: 31,
    },
    {
      name: "Batiks",
      description: "Traditional wax-resist dyeing textiles and art pieces",
      image: batick1,
      count: 9,
    },
    {
      name: "decorations",
      description: "Amaizing decorations and art pieces",
      image: decoration,
      count: 15,
    },
  ];
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const cardVariants = {
    hidden: {
      y: 50,
      opacity: 0,
      scale: 0.9,
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
    hover: {
      scale: 1.05,
      rotateY: 5,
      boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <>
      <SEOMetadata
        title="African Cultural Artifacts - Product Curio shop bobex Categories"
        description="Browse our diverse collection of African cultural artifacts. From intricate masks to elegant jewelry, explore the rich artistic traditions of African craftsmanship."
        keywords="African art categories, masks, sculptures, jewelry, textiles, batiks, decorative artifacts, curio shop sagana, bobex fine art, curio shop"
        canonicalUrl="https://bobexcurio.com/categories"
        ogImage="/og-categories-image.jpg"
      />
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 bg-white dark:bg-secondary-dark dark:text-white">
        <motion.div
          initial="hidden"
          animate="visible"
          className="max-w-7xl mx-auto"
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Explore Our Collections
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Discover unique cultural artifacts from around the world
            </p>
          </motion.div>

          <motion.div
            ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8"
          >
            {categories.map((category) => (
              <motion.div
                key={category.name}
                variants={cardVariants}
                whileHover="hover"
                className="group relative overflow-hidden rounded-xl bg-white dark:bg-gray-800 shadow-lg cursor-pointer"
                onClick={() =>
                  navigate(`/categories/${category.name.toLowerCase()}`)
                }
              >
                <div className="aspect-w-16 aspect-h-9 relative">
                  <img
                    src={category.image}
                    loading="lazy"
                    title="category"
                    alt={category.name}
                    className="object-cover w-full h-64 transition-transform duration-300 group-hover:scale-110"
                  />
                  {/* Updated overlay with better visibility */}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/60 to-black/30 group-hover:from-black/90 group-hover:via-black/70 group-hover:to-black/40 transition-opacity duration-300" />
                </div>

                <motion.div
                  className="absolute bottom-0 left-0 right-0 p-6 text-white"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <h3 className="text-2xl font-bold mb-3 drop-shadow-lg">
                    {category.name}
                  </h3>
                  <p className="text-sm mb-3 text-gray-100 drop-shadow-lg leading-relaxed">
                    {category.description}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-200 drop-shadow-lg">
                      {category.count} items
                    </span>
                    <motion.span
                      className="inline-flex items-center text-yellow-400 font-bold group-hover:text-yellow-300 transition-colors duration-300"
                      whileHover={{ x: 5 }}
                    >
                      Explore →
                    </motion.span>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};

export default CategoriesPage;
