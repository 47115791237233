import React from "react";
import { motion } from "framer-motion";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import curioshop from "../../assert/shop/shop1.jpg";
import curioshop2 from "../../assert/shop/shop2.jpg";
import curioshop3 from "../../assert/shop/shop3.jpg";
import curioshop4 from "../../assert/shop/shop4.jpg";
import { useDarkModeContext } from "../../context/DarkModeContext";
const AboutSection = () => {
  const { ref, controls } = useScrollAnimation();
  const { isDarkMode } = useDarkModeContext();

  // Content animation variants
  const contentVariants = {
    hidden: {
      opacity: 0,
      x: -50,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        mass: 1,
        damping: 12,
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  // Text element variants
  const textVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  // Gallery section variants
  const galleryVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        mass: 1,
        damping: 12,
        staggerChildren: 0.15,
        delayChildren: 0.2,
      },
    },
  };

  // Individual image variants
  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  // Button animation variants
  const buttonVariants = {
    rest: {
      scale: 1,
      backgroundColor: "#8B4513",
    },
    hover: {
      scale: 1.05,
      backgroundColor: "#6B3410",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    tap: {
      scale: 0.95,
    },
  };

  return (
    <section
      ref={ref}
      className={`py-16 ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-white text-black"
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <motion.div
            className="md:w-1/2 mb-8 md:mb-0 md:pr-8"
            variants={contentVariants}
            initial="hidden"
            animate={controls}
          >
            <motion.h2
              className={`text-3xl font-bold mb-4 ${
                !isDarkMode && `text-[#3E2723]`
              }`}
              variants={textVariants}
            >
              About Our Curio Shop
            </motion.h2>

            <motion.p className="text-lg mb-4" variants={textVariants}>
              At our Curio Shop, we're passionate about bringing the rich
              cultural heritage of Kenya and Africa to the world. Each artifact
              in our collection tells a unique story, handcrafted by skilled
              artisans using traditional techniques passed down through
              generations.
            </motion.p>

            <motion.p className="text-lg" variants={textVariants}>
              We work directly with local communities to ensure fair trade
              practices and to support the preservation of these ancient crafts.
              When you buy from us, you're not just acquiring a beautiful piece
              of art – you're becoming part of a legacy.
            </motion.p>

            <motion.button
              className="mt-6 text-white px-6 py-3 rounded-full font-semibold"
              variants={buttonVariants}
              initial="rest"
              whileHover="hover"
              whileTap="tap"
            >
              Learn More
            </motion.button>
          </motion.div>

          <motion.div
            className="md:w-1/2 grid grid-cols-2 gap-4"
            variants={galleryVariants}
            initial="hidden"
            animate={controls}
          >
            {[curioshop, curioshop2, curioshop3, curioshop4].map(
              (image, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-md"
                  variants={imageVariants}
                  whileHover="hover"
                >
                  <motion.img
                    src={image}
                    alt={`Shop image ${index + 1}`}
                    className="w-full h-48 object-cover"
                  />
                </motion.div>
              )
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
