import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useDarkModeContext } from "../../context/DarkModeContext";

const Card = ({ item, handleScrollToTop }) => {
  const { isDarkMode } = useDarkModeContext();
  return (
    <motion.div
      className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
      whileHover={{ y: -5 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Link
        to={`/item/${item.id}`}
        state={{ item }}
        onClick={handleScrollToTop}
      >
        <div className="relative overflow-hidden group">
          <img
            src={item.image}
            alt={item.name}
            loading="lazy"
            className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300 flex items-center justify-center">
            <motion.span
              className="text-white text-lg font-semibold px-4 py-2 bg-[#8B4513] rounded-full opacity-0 group-hover:opacity-100"
              initial={{ y: 20 }}
              whileHover={{ y: 0 }}
            >
              View Details
            </motion.span>
          </div>
        </div>
        <div className="p-4">
          <h3 className="text-xl font-semibold text-[#3E2723] mb-2">
            {item.name}
          </h3>
          <p className="text-lg font-bold text-[#8B4513]">{item.timeFrame}</p>
          <motion.button
            className={`mt-4 w-full ${isDarkMode ? "bg-gray-900 text-white" : "bg-[#8B4513] text-white"} py-2 rounded-full font-semibold hover:bg-opacity-90 transition-colors`}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
          >
            View Details
          </motion.button>
        </div>
      </Link>
    </motion.div>
  );
};

export default Card;
