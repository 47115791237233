import React from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useDarkModeContext } from "../../context/DarkModeContext";
import { Link } from "react-router-dom";

const Footer = () => {
  const { isDarkMode } = useDarkModeContext();
  return (
    <footer
      className={` text-white py-12 ${isDarkMode ? "bg-gray-900 text-white" : "bg-[#8B4513]"}`}
    >
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Curio Shop</h3>
            <p className="mb-4">
              Discover the beauty of African artifacts and culture.
            </p>
            <div className="flex space-x-4">
              <motion.a
                href="#"
                whileHover={{ scale: 1.2 }}
                className="text-2xl"
              >
                <FaFacebook />
              </motion.a>
              <motion.a
                href="#"
                whileHover={{ scale: 1.2 }}
                className="text-2xl"
              >
                <FaInstagram />
              </motion.a>
              <motion.a
                href="#"
                whileHover={{ scale: 1.2 }}
                className="text-2xl"
              >
                <FaTwitter />
              </motion.a>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {[
                { name: "Home", url: "/" },
                { name: "Categories", url: "/categories" },
                { name: "About Us", url: "/about" },
                { name: "Contact", url: "/contact" },
              ].map((item) => (
                <li key={item}>
                  <Link
                    to={item.url}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    className="hover:text-[#FFD700] transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Newsletter</h3>
            <p className="mb-4">
              Stay updated with our latest arrivals and offers.
            </p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-white/20 text-center">
          <p>&copy; 2024 Curio Shop. All rights reserved.</p>
          <div className="flex gap-2 justify-center">
            <p>Developed by: Dennis Onyango denisonyango0307@gmail.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
