import React from "react";
import { motion } from "framer-motion";
import ItemGallery from "../components/item/ItemGallery";
import sculpture1 from "../assert/sculptures/sculp1.jpg";
import sculpture2 from "../assert/sculptures/sculp2.jpg";
import sculpture3 from "../assert/sculptures/sculp3.jpg";
import sculpture4 from "../assert/sculptures/sculp4.jpg";
import sculpture5 from "../assert/sculptures/sculp5.jpg";
import sculpture6 from "../assert/sculptures/sculp6.jpg";
import sculpture7 from "../assert/sculptures/sculp7.jpg";
import sculpture8 from "../assert/sculptures/sculp8.jpg";
import sculpture9 from "../assert/sculptures/sculp9.jpg";
import sculpture10 from "../assert/sculptures/sculpt10.jpg";
import sculpture11 from "../assert/sculptures/sculp11.jpg";
import sculpture12 from "../assert/sculptures/sculp12.jpg";
import sculpture13 from "../assert/sculptures/sculp13.jpg";
import sculpture14 from "../assert/sculptures/scult27.jpg";
import sculpture15 from "../assert/sculptures/sculp15.jpg";
import sculpture16 from "../assert/sculptures/sculp16.jpg";
import sculpture17 from "../assert/sculptures/sculp17.jpg";
import sculpture18 from "../assert/sculptures/sculp18.jpg";
import sculpture19 from "../assert/sculptures/sculp19.jpg";
import sculpture20 from "../assert/sculptures/sculp20.jpg";
import sculpture21 from "../assert/sculptures/sculp21.jpg";
import sculpture22 from "../assert/sculptures/sculp22.jpg";
import sculpture23 from "../assert/sculptures/sculp23.jpg";
import sculpture24 from "../assert/sculptures/sculpt24.jpg";
import sculpture25 from "../assert/sculptures/sculpt25.jpg";
import sculpture26 from "../assert/sculptures/sculpt26.jpg";
import sculpture27 from "../assert/sculptures/scult27.jpg";
import sculpture28 from "../assert/sculptures/sculpt28.jpg";
import sculpture29 from "../assert/sculptures/sculpt29.jpg";
import sculpture30 from "../assert/sculptures/sculp30.jpg";
import sculpture31 from "../assert/sculptures/sculpt31.jpg";
import SEOMetadata from "../components/SEOHelment";
export const SculpturesPage = () => {
  const sculptures = [
    {
      id: 1,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture1,
      description: "Antique bronze Buddha statue with detailed craftsmanship",
      materials: "Bronze with patina",
      height: "24 inches",
      weight: "8.5 kg",
      significance: "Religious artifact",
      condition: "Excellent vintage condition",
      authentication: "Certified by Asian Art Association",
    },
    {
      id: 2,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture2,
      description:
        "Traditional wooden fertility figure with ritual significance",

      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 3,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture3,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 4,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture4,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 5,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture5,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 6,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture6,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 7,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture7,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 8,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture8,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 9,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture9,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 10,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture10,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 11,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture11,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 12,
      name: "Sculpture Collection",
      origin: "Africa",
      period: "Varies with the item",
      image: sculpture12,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 13,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture13,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 14,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture14,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 15,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture15,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 16,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture16,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 17,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture17,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 18,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture18,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 19,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture19,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 20,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture20,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 21,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture21,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "5.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 22,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture22,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 23,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture23,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 24,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture24,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 25,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture25,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 26,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture26,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 27,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture27,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 28,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture28,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 29,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture29,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    {
      id: 30,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture30,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      weight: "Varies by piece",
      significance:
        "This collection represents traditional Kenyan values, often symbolizing unity, resilience, and a deep connection to nature and community.",
      condition:
        "Good condition with minor age-related wear, maintaining its cultural authenticity",
      authentication: "Certified by African Art Society",
      provenance:
        "Sourced from private collections and Kenyan artisans, with historical and cultural significance",
      cultural_context:
        "These sculptures are a testament to Kenyan craftsmanship, reflecting the artistry and heritage passed down through generations.",
      authentication: "Tribal art expert verified",
    },
    {
      id: 31,
      name: "Sculpture Collection",
      origin: "Kenya",
      period: "Varies with the item",
      image: sculpture31,
      description:
        "Traditional wooden fertility figure with ritual significance",
      materials: "Hardwood, natural pigments",
      height: "18 inches",
      weight: "3.2 kg",
      significance: "Fertility ritual artifact",
      condition: "Good, with natural aging",
      authentication: "Tribal art expert verified",
    },
    // Add more sculptures as needed
  ];

  return (
    <>
      <SEOMetadata
        title="Curio shop sagana bobex fine art African Sculptures - Artistic Cultural Expressions"
        description="Explore our remarkable collection of African sculptures. Handcrafted by skilled artisans, these pieces embody the rich sculptural traditions of various African cultures."
        keywords="African sculptures, wooden sculptures, cultural art, traditional sculpting, African artistic heritage, bobex fine art, curio shop sagana "
        canonicalUrl="https://bobexcurio.com/categories/sculptures"
        ogImage="/og-sculptures-image.jpg"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white dark:bg-secondary-dark p-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-primary dark:text-white mb-4">
              Cultural Sculptures
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Explore our collection of authentic sculptures representing
              diverse cultural heritage
            </p>
          </motion.div>
          <ItemGallery items={sculptures} />
        </div>
      </motion.div>
    </>
  );
};
