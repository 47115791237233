import React from "react";
import { motion } from "framer-motion";
import ItemGallery from "../components/item/ItemGallery";
import textile1 from "../assert/textiles&arts/textile1.jpg";
import art1 from "../assert/textiles&arts/art1.jpg";
import art2 from "../assert/textiles&arts/art2.jpg";
import art3 from "../assert/textiles&arts/art3.jpg";
import art4 from "../assert/textiles&arts/art4.jpg";
import art5 from "../assert/textiles&arts/art5.jpg";
import art6 from "../assert/textiles&arts/art6.jpg";
import art7 from "../assert/textiles&arts/art7.jpg";
import art8 from "../assert/textiles&arts/art8.jpg";
import art9 from "../assert/textiles&arts/art9.jpg";
import art10 from "../assert/textiles&arts/art10.jpg";
import art12 from "../assert/textiles&arts/art12.jpg";
import art13 from "../assert/textiles&arts/art13.jpg";
import art14 from "../assert/textiles&arts/art14.jpg";
import art15 from "../assert/textiles&arts/art15.jpg";
import art16 from "../assert/textiles&arts/art16.jpg";
import art17 from "../assert/textiles&arts/art17.jpg";
import SEOMetadata from "../components/SEOHelment";
export const TextilesPage = () => {
  const textiles = [
    {
      id: 1,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: textile1,
      description:
        "Intricately woven silk carpet with traditional Persian motifs",
      price: 3500,
      materials: "Pure silk",
      dimensions: "6ft x 9ft",
    },
    {
      id: 2,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art1,
      description:
        "This stunning textile walls and arts hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 3,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art2,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 4,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art3,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 5,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art4,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 6,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art5,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 7,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art6,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 8,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art7,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 9,
      name: "Vibrant African Textile Wall Hanging",
      origin: "Africa",
      technique: "Hand-woven, Hand-dyed, Hand-embroidered",
      image: art8,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 10,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art9,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 11,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art10,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 12,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art12,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 13,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art13,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 14,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art14,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 15,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art15,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 16,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art16,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
    {
      id: 17,
      name: "Persian Silk Carpet",
      origin: "African",
      technique: "Hand-knotted",
      image: art17,
      description:
        "This stunning textile wall hanging showcases the remarkable artistry and rich cultural heritage of traditional and contemporary African textile arts. Masterfully hand-woven using premium natural fibers, the piece features a captivating blend of vibrant colors, intricate geometric patterns, and symbolic motifs inspired by the diverse artistic traditions across the African continent. The meticulous craftsmanship, from the dyeing process to the intricate embroidered detailing, elevates this textile to a true work of fine art that commands attention and sparks wonder. This unique piece serves as a striking visual representation of Africa's enduring artistic legacy.",
      price: 850,
      materials: "Cotton, Raffia, Vegetable-based dyes",
    },
  ];

  return (
    <>
      <SEOMetadata
        title="African Textiles - Woven Cultural Narratives"
        description="Discover the rich textile traditions of Africa. Our collection features hand-woven fabrics, intricate patterns, and traditional textile art that reflect the diverse cultural expressions of African communities."
        keywords="African textiles, traditional fabrics, hand-woven textiles, cultural textile art, African fabric patterns, ethnic weaving, sagana curio shop, bobex fine art, art, sagana"
        canonicalUrl="https://bobexcurio.com/categories/textiles"
        ogImage="/og-textiles-image.jpg"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white dark:bg-secondary-dark p-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-primary dark:text-white mb-4">
              Traditional Arts and Textiles
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Explore handcrafted textiles that showcase centuries of artisanal
              expertise
            </p>
          </motion.div>
          <ItemGallery items={textiles} />
        </div>
      </motion.div>
    </>
  );
};
