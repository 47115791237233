import React from "react";
import Hero from "../components/home/Hero";
import FeaturedItems from "../components/home/FeaturedItems";
import CategorySection from "../components/home/CategorySection";
import AboutSection from "../components/home/AboutSection";
import SEOMetadata from "../components/SEOHelment";
const Home = () => {
  return (
    <>
      <SEOMetadata
        title="African Cultural Artifacts - Bobex Fine Art - Curio Shop"
        description="Collection of authentic African cultural artifacts. Explore masks, sculptures, jewelry, textiles, batiks, and unique decorative pieces."
        keywords="African artifacts, cultural art, handcrafted African art, traditional masks, African sculptures, ethnic jewelry, African textiles, batik art, cultural decor, curio shop , bobexcurio"
        canonicalUrl="https://bobexcurio.com"
        ogImage="/og-home-image.jpg"
      />
      <Hero />
      <FeaturedItems />
      <CategorySection />
      <AboutSection />
    </>
  );
};

export default Home;
