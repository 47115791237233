import React from "react";
import { motion } from "framer-motion";
import Card from "../common/Card";

const RelatedItems = ({ items, handleScrollToTop }) => {
  return (
    <section className="py-12 bg-gray-100 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">
          Related Items
        </h2>
        <motion.div
          className="flex overflow-x-auto pb-4 -mx-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {items.map((item) => (
            <motion.div
              key={item.id}
              className="px-4 w-64 flex-shrink-0"
              whileHover={{ scale: 1.05 }}
            >
              <Card item={item} handleScrollToTop={handleScrollToTop} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default RelatedItems;
