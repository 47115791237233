import React, { useState } from "react";
import { motion } from "framer-motion";

const ItemGallery = ({ items }) => {
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [mainImage, setMainImage] = useState(selectedItem?.image);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    if (!isZoomed) return;

    const image = e.currentTarget;
    const { left, top, width, height } = image.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    setZoomPosition({ x, y });
  };

  // Helper function to render dynamic properties
  const renderProperty = (label, value) => {
    if (!value) return null;
    return (
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">{label}</h2>
        <p className="text-gray-700 dark:text-white">
          {Array.isArray(value) ? value.join(", ") : value}
        </p>
      </div>
    );
  };

  return (
    <div className="min-h-screen dark:text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Images */}
          <div className="md:w-1/3">
            {/* Main Image Display */}
            <motion.div
              className="relative overflow-hidden rounded-lg shadow-lg mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div
                className="relative w-full h-96"
                onMouseEnter={() => setIsZoomed(true)}
                onMouseLeave={() => setIsZoomed(false)}
                onMouseMove={handleMouseMove}
              >
                <img
                  src={mainImage}
                  alt={selectedItem.name}
                  loading="lazy"
                  className={`w-full h-full object-cover transition-transform duration-200 ${
                    isZoomed ? "scale-150" : "scale-100"
                  }`}
                  style={
                    isZoomed
                      ? {
                          transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
                        }
                      : {}
                  }
                />
              </div>
            </motion.div>

            {/* Item Selection Thumbnails */}
            <div className="grid grid-cols-3 gap-2">
              {items.map((item, index) => (
                <motion.div
                  key={item.id || index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`cursor-pointer rounded-lg overflow-hidden border-2 ${
                    selectedItem.id === item.id
                      ? "border-blue-500"
                      : "border-transparent"
                  }`}
                  onClick={() => {
                    setSelectedItem(item);
                    setMainImage(item.image);
                  }}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    loading="lazy"
                    className="w-full h-24 object-cover"
                  />
                </motion.div>
              ))}
            </div>
          </div>

          {/* Right Column - Item Details */}
          <div className="md:w-2/3">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-6"
            >
              <h1 className="text-3xl font-bold mb-4">{selectedItem.name}</h1>
              {renderProperty("Description", selectedItem.description)}
              {renderProperty("Origin", selectedItem.origin)}
              {renderProperty("Culture", selectedItem.culture)}
              {renderProperty("Period", selectedItem.period)}
              {renderProperty("Style", selectedItem.style)}
              {renderProperty("Materials", selectedItem.materials)}
              {renderProperty("Technique", selectedItem.technique)}
              {renderProperty("Dimensions", selectedItem.dimensions)}
              {renderProperty("Height", selectedItem.height)}
              {renderProperty("Weight", selectedItem.weight)}
              {renderProperty("Significance", selectedItem.significance)}
              {renderProperty("Condition", selectedItem.condition)}
              {renderProperty("Authentication", selectedItem.authentication)}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemGallery;
