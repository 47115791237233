import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  FaRegHandshake,
  FaEnvelope,
  FaPhone,
  FaFacebook,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { ImAddressBook } from "react-icons/im";
import { BsDoorOpen } from "react-icons/bs";
import SEOMetadata from "../components/SEOHelment";

const ContactPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      <SEOMetadata
        title="Contact African Cultural Artifacts Bobex fine art sagana - Curio Shop"
        description="Get in touch with our African art experts. We're passionate about connecting you with authentic, meaningful cultural artifacts and sharing the stories behind each piece."
        keywords="contact Bobex fine art sagana Curio Shop African art shop, cultural artifact inquiries, African art consultation, artifact purchase support"
        canonicalUrl="https://bobexcurio.com/contact"
        ogImage="/og-contact-image.jpg"
      />
      <motion.div
        className="min-h-screen bg-white dark:bg-secondary-dark text-black dark:text-white p-8"
        initial="hidden"
        animate={controls}
        variants={containerVariants}
        ref={ref}
      >
        <motion.h1
          className="text-4xl font-bold mb-8 text-center"
          variants={itemVariants}
        >
          Contact Us
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-semibold mb-4 flex gap-3 items-center">
              Get in Touch <FaRegHandshake />
            </h2>
            <ul className="space-y-4">
              <motion.li whileHover={{ scale: 1.05 }}>
                <div className="flex items-center gap-2">
                  <strong>
                    <ImAddressBook />
                  </strong>{" "}
                  86J9+WR, Sagana Sagana Curio Shop, address
                </div>
              </motion.li>
              <motion.li whileHover={{ scale: 1.05 }}>
                <a href="tel:+254727639616" className="flex items-center gap-2">
                  <FaPhone /> (254) 722864616 / (254) 727639616
                </a>
              </motion.li>
              <motion.li whileHover={{ scale: 1.05 }}>
                <a
                  href="mailto:paynesfineart@gmail.com"
                  className="flex items-center gap-2"
                >
                  <FaEnvelope /> paynesfineart@gmail.com
                </a>
              </motion.li>
              <motion.li whileHover={{ scale: 1.05 }}>
                <div className="flex gap-2 items-center">
                  <strong>
                    <BsDoorOpen />
                  </strong>{" "}
                  Mon-Sat: 6am-7pm
                </div>
              </motion.li>
            </ul>
          </motion.div>

          <motion.div
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg overflow-hidden"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-semibold mb-4 flex gap-3 items-center">
              Find Us <MdLocationPin />
            </h2>
            <div className="relative h-0 pb-[56.25%]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.547376994961!2d37.21701467496473!3d-0.6677328993257485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18289f95f482fdb9%3A0x1511d6a2ada7f0de!2sSagana%20Curio%20Shop!5e0!3m2!1sen!2ske!4v1729522935287!5m2!1sen!2ske"
                width="100%"
                height="100%"
                style={{ border: 0, position: "absolute", top: 0, left: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </motion.div>
        </div>

        <motion.div
          className="mt-12 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg md:flex  items-center md:justify-between justify-center"
          variants={itemVariants}
        >
          <h2 className="text-2xl font-semibold mb-4">Connect With Us</h2>
          {/* <div className="flex items-center space-x-6 ">
          <motion.a
            href="https://facebook.com/curioshop"
            target="_blank"
            rel="noopener noreferrer"
            className="text-3xl text-blue-600 dark:text-blue-400"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaFacebook />
          </motion.a>
          <motion.a
            href="https://twitter.com/curioshop"
            target="_blank"
            rel="noopener noreferrer"
            className="text-3xl text-blue-400 dark:text-blue-300"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaTwitter />
          </motion.a>
          <motion.a
            href="https://instagram.com/curioshop"
            target="_blank"
            rel="noopener noreferrer"
            className="text-3xl text-pink-600 dark:text-pink-400"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaInstagram />
          </motion.a>
        </div> */}
        </motion.div>
      </motion.div>
    </>
  );
};

export default ContactPage;
