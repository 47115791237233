import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const CulturalContext = ({ context }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mt-8 bg-gray-100 dark:bg-gray-800 rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
        Cultural Context
      </h2>
      <motion.div
        initial={{ height: 100 }}
        animate={{ height: isExpanded ? "auto" : 100 }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <p className="text-gray-600 dark:text-gray-300">{context}</p>
      </motion.div>
      <AnimatePresence>
        {!isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-gray-100 dark:from-gray-800 to-transparent"
          />
        )}
      </AnimatePresence>
      <motion.button
        className="mt-4 text-blue-600 dark:text-blue-400 font-semibold"
        onClick={() => setIsExpanded(!isExpanded)}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {isExpanded ? "Read Less" : "Read More"}
      </motion.button>
    </div>
  );
};

export default CulturalContext;
