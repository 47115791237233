import React from "react";
import { motion } from "framer-motion";
import ItemGallery from "../components/item/ItemGallery";
import jewel1 from "../assert/jewelries/jewelry1.jpg";
import jewel2 from "../assert/jewelries/jewelry2.jpg";
import jewel3 from "../assert/jewelries/jewelry3.jpg";
import jewel4 from "../assert/jewelries/jewelry4.jpg";
import jewel5 from "../assert/jewelries/jewelry5.jpg";
import jewel6 from "../assert/jewelries/jewelry6.jpg";
import jewel7 from "../assert/jewelries/jewelry7.jpg";
import jewel8 from "../assert/jewelries/jewelry8.jpg";
import jewel9 from "../assert/jewelries/jewelry9.jpg";
import jewel10 from "../assert/jewelries/jewelry10.jpg";
import jewel11 from "../assert/jewelries/jewelry11.jpg";
import jewel12 from "../assert/jewelries/jewelry12.jpg";
import jewel13 from "../assert/jewelries/jewelry13.jpg";
import jewel14 from "../assert/jewelries/jewelry14.jpg";
import jewel15 from "../assert/jewelries/jewelry15.jpg";
import jewel16 from "../assert/jewelries/jewlery16.jpg";
import jewel17 from "../assert/jewelries/jewelry17.jpg";
import SEOMetadata from "../components/SEOHelment";
export const JewelryPage = () => {
  const jewelry = [
    {
      id: 1,
      name: "Tribal Silver Necklace",
      origin: "Kenya, Central Region",
      style: "Traditional",
      image: jewel1,
      description: "Handcrafted silver necklace with traditional tribal motifs",
      price: 850,
      materials: "Sterling Silver, Semi-precious stones",
      weight: "120g",
      period: "Contemporary",
      technique: "Hand-forged",
    },
    {
      id: 2,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel2,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      period: "Contemporary",
      technique: "Bead weaving",
    },
    {
      id: 3,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel3,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
    },
    {
      id: 4,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel4,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Beauty symbol",
    },
    {
      id: 5,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel5,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    {
      id: 6,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel6,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    {
      id: 7,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel7,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
    },
    {
      id: 8,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel8,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
    },
    {
      id: 9,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel9,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      period: "Contemporary",
      technique: "Bead weaving",
    },
    {
      id: 10,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel10,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "50g",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    {
      id: 11,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel11,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
    },
    {
      id: 12,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel12,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      significance: "Warrior status symbol",
    },
    {
      id: 13,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel13,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      significance: "Warrior status symbol",
    },
    {
      id: 14,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel14,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    {
      id: 15,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel15,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    {
      id: 16,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel16,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      materials: "Glass beads, Leather",
      weight: "45g",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    {
      id: 17,
      name: "African Beaded Bracelet",
      origin: "Kenya",
      style: "Tribal",
      image: jewel17,
      description: "Authentic Maasai beaded bracelet with traditional patterns",
      period: "Contemporary",
      technique: "Bead weaving",
      significance: "Warrior status symbol",
    },
    // Add more jewelry items as needed
  ];

  return (
    <>
      <SEOMetadata
        title="Curio shop sagana bobex fine art African Jewelry - Handcrafted Cultural Treasures"
        description="Explore our exquisite collection of African jewelry, showcasing intricate craftsmanship, traditional techniques, and cultural significance. Each piece tells a unique story of African artistic heritage."
        keywords="African jewelry, tribal accessories, handcrafted jewelry, ethnic ornaments, cultural jewelry, traditional African adornments, curio shop, sagana bobex fine art, bobex fine art"
        canonicalUrl="https://bobexcurio.com/categories/jewelry"
        ogImage="/og-jewelry-image.jpg"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white dark:bg-secondary-dark p-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-primary dark:text-white mb-4">
              Cultural Jewelry Collection
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Discover handcrafted jewelry pieces that tell stories of tradition
              and artistry
            </p>
          </motion.div>
          <ItemGallery items={jewelry} />
        </div>
      </motion.div>
    </>
  );
};
