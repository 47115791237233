import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { RiChatHistoryFill } from "react-icons/ri";
import { SiTransmission } from "react-icons/si";
import shop from "../assert/shop/shop1.jpg";
import SEOMetadata from "../components/SEOHelment";
const AboutPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      <SEOMetadata
        title="About Our African Cultural Artifacts Collection Curio Shop bobex fine art sagana"
        description="Learn about our mission to preserve and share the rich cultural heritage of African artistry. We curate unique, authentic artifacts that bridge traditional craftsmanship and contemporary design."
        keywords="African art preservation, cultural heritage, artisan crafts, African artistic traditions, cultural artifacts collection, sagana, bobex fine art, curio shop"
        canonicalUrl="https://bobexcurio.com/about"
        ogImage={shop}
      />
      <motion.div
        className="min-h-screen bg-white dark:bg-secondary-dark text-black dark:text-white p-8"
        initial="hidden"
        animate={controls}
        variants={containerVariants}
        ref={ref}
      >
        <motion.h1
          className="text-4xl font-bold mb-8 text-center"
          variants={itemVariants}
        >
          About Our Curio Shop
        </motion.h1>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-8"
          variants={itemVariants}
        >
          <motion.div
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h2 className="text-2xl font-semibold mb-4 flex gap-2 items-center">
              Our Story <RiChatHistoryFill />{" "}
            </h2>
            <p>
              Founded in 2010, our curio shop has been a haven for collectors
              and enthusiasts alike. We specialize in rare and unique items from
              around the world, each with its own fascinating history.
            </p>
          </motion.div>

          <motion.div
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h2 className="text-2xl font-semibold mb-4 flex gap-3 items-center">
              Our Mission <SiTransmission />
            </h2>
            <p>
              Our mission is to preserve and share the wonder of rare and
              curious objects. We believe that every item has a story to tell
              and we're here to help those stories live on.
            </p>
          </motion.div>
        </motion.div>
        <motion.div
          className="flex items-center justify-center mt-6"
          variants={itemVariants}
        >
          <motion.img
            className="mt-12 rounded-lg"
            src={shop}
            alt="Curiosities"
            loading="lazy"
            variants={itemVariants}
          />
        </motion.div>

        <motion.div className="mt-12" variants={itemVariants}>
          <h2 className="text-3xl font-semibold mb-6 text-center">
            What Makes Us Unique
          </h2>
          <ul className="list-disc list-inside space-y-4">
            <motion.li whileHover={{ scale: 1.05 }}>
              Carefully curated collection of rare and unique items
            </motion.li>
            <motion.li whileHover={{ scale: 1.05 }}>
              Expert knowledge and passionate staff
            </motion.li>
            <motion.li whileHover={{ scale: 1.05 }}>
              Worldwide sourcing to bring you the most interesting curios
            </motion.li>
            <motion.li whileHover={{ scale: 1.05 }}>
              Commitment to ethical and sustainable practices
            </motion.li>
          </ul>
        </motion.div>
      </motion.div>
    </>
  );
};

export default AboutPage;
