import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDarkModeContext } from "../../context/DarkModeContext";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/Sheet.tsx";
import { Menu } from "lucide-react";

const Header = () => {
  const { isDarkMode } = useDarkModeContext();
  const navItems = ["Home", "Categories", "About", "Contact"];
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  // Animation variants
  const headerVariants = {
    hidden: { y: -20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: -20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };

  const handleNavigation = (path) => {
    setIsOpen(false); // Close the sheet
    navigate(path === "Home" ? "/" : `/${path.toLowerCase()}`);
  };

  const NavLinks = ({ isMobile = false }) => (
    <motion.ul
      className={`${
        isMobile ? "flex flex-col space-y-4" : "hidden md:flex md:space-x-6"
      }`}
      variants={listVariants}
      initial="hidden"
      animate="visible"
    >
      {navItems.map((item) => (
        <motion.li
          key={item}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          className={isMobile ? "w-full" : ""}
        >
          {isMobile ? (
            <button
              onClick={() => handleNavigation(item)}
              className="hover:text-[#FFD700] transition-colors block w-full py-2 text-left"
            >
              {item}
            </button>
          ) : (
            <Link
              to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
              className="hover:text-[#FFD700] transition-colors"
            >
              {item}
            </Link>
          )}
        </motion.li>
      ))}
    </motion.ul>
  );
  return (
    <motion.header
      className={` text-white ${isDarkMode ? "bg-gray-900 text-white" : "bg-[#8B4513]"}`}
      variants={headerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <motion.h1
          className="md:text-3xl text-lg font-bold"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Link to="/">Bobex Fine Arts</Link>
        </motion.h1>
        {/* Desktop Navigation */}
        <NavLinks />
        {/* Mobile Navigation */}
        <div className="md:hidden">
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-2"
              >
                <Menu className="h-6 w-6" />
              </motion.button>
            </SheetTrigger>
            <SheetContent
              side="right"
              className={`${
                isDarkMode
                  ? "bg-gray-900 text-white"
                  : "bg-[#8B4513] text-white"
              }`}
            >
              <SheetHeader>
                <SheetTitle
                  className={`${
                    isDarkMode ? "text-white" : "text-white"
                  } text-xl font-bold`}
                >
                  Menu
                </SheetTitle>
              </SheetHeader>
              <div className="mt-8">
                <NavLinks isMobile={true} />
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
