import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { useDarkModeContext } from "../../context/DarkModeContext";
import { FaSun, FaMoon } from "react-icons/fa";

const Layout = ({ children }) => {
  const { isDarkMode, toggleDarkMode } = useDarkModeContext();

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? "dark" : ""}`}>
      <Header />
      <motion.main
        className="flex-grow bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.main>
      <Footer />
      <button
        onClick={toggleDarkMode}
        className="fixed bottom-4 right-4 p-2 rounded-full bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-white"
      >
        {isDarkMode ? <FaSun /> : <FaMoon />}
      </button>
    </div>
  );
};

export default Layout;
