import React from "react";
import { motion } from "framer-motion";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import categoryMask from "../../assert/marks/mask8.jpg";
import { Link } from "react-router-dom";
import textiles from "../../assert/textiles&arts/textile1.jpg";
import sculptures from "../../assert/sculptures/sculp12.jpg";
import jewelries from "../../assert/jewelries/jewelrycat.jpg";
import { useDarkModeContext } from "../../context/DarkModeContext";
const categories = [
  { name: "Masks", image: categoryMask },
  { name: "Textiles", image: textiles },
  { name: "Jewelry", image: jewelries },
  { name: "Sculptures", image: sculptures },
];

const CategorySection = () => {
  const { ref, controls } = useScrollAnimation();
  const { isDarkMode } = useDarkModeContext();

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  // Hover animation for category cards
  const cardHoverVariants = {
    rest: {
      scale: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <section
      ref={ref}
      className={`py-16 ${isDarkMode ? "bg-gray-900 text-white" : "bg-[#8B4513] text-white"}`}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold mb-8 text-center"
          variants={titleVariants}
          initial="hidden"
          animate={controls}
        >
          Explore Categories
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
          variants={containerVariants}
          initial="hidden"
          animate={controls}
        >
          {categories.map((category) => (
            <motion.div
              key={category.name}
              className="relative overflow-hidden rounded-lg shadow-lg group cursor-pointer"
              variants={itemVariants}
              initial="rest"
              whileHover="hover"
              animate={controls}
            >
              <Link to={`/categories/${category.name.toLowerCase()}`}>
                <motion.div
                  className="relative w-full h-64"
                  variants={cardHoverVariants}
                >
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-full h-full object-cover"
                  />
                  <motion.div
                    className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center"
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <h3 className="text-white text-2xl font-semibold">
                      {category.name}
                    </h3>
                  </motion.div>
                </motion.div>
              </Link>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default CategorySection;
