import React from "react";
import { motion } from "framer-motion";
import ItemGallery from "../components/item/ItemGallery";

import decoration from "../assert/decorations/decoratiosn1.jpg";
import decoration2 from "../assert/decorations/decorations2.jpg";
import decoration3 from "../assert/decorations/decoration3.jpg";
import decoration4 from "../assert/decorations/decoration4.jpg";
import decoration5 from "../assert/decorations/decorations5.jpg";
import decoration6 from "../assert/decorations/decorations6.jpg";
import decoration7 from "../assert/decorations/decorations7.jpg";
import decoration8 from "../assert/decorations/decorations8.jpg";
import decoration9 from "../assert/decorations/decorations9.jpg";
import decoration10 from "../assert/decorations/decorations10.jpg";
import decoration11 from "../assert/decorations/decorations11.jpg";
import decoration12 from "../assert/decorations/decorations12.jpg";
import decoration13 from "../assert/decorations/decorations13.jpg";
import decoration15 from "../assert/decorations/decorations15.jpg";
import decoration16 from "../assert/decorations/decorations16.jpg";
import decoration17 from "../assert/decorations/decoration17.jpg";
import SEOMetadata from "../components/SEOHelment";
export const Decorations = () => {
  const clientStories = [
    {
      id: 1,
      name: "The Wedding Collection Story",
      culture: "Cross-cultural",
      period: "2023",
      image: decoration,
      description:
        "A touching story of a bride who found her perfect wedding accessories, combining both Eastern and Western elements",
      significance: "Cultural fusion",
      materials: "Various artifacts featured",
      testimonial:
        "The shop helped me create the perfect fusion wedding look, combining my heritage with modern style.",
      client: "Sarah Chen",
      date: "June 2023",
    },
    {
      id: 2,
      name: "The Museum Curator's Discovery",
      culture: "African Art",
      period: "2023",
      image: decoration2,
      description:
        "How a museum curator found a rare piece that became the centerpiece of their African Art exhibition",
      significance: "Museum acquisition",
      materials: "Rare artifacts",
      testimonial:
        "This shop is a treasure trove of authentic pieces. The knowledge and curation are exceptional.",
      client: "Dr. James Miller",
      date: "August 2023",
    },
    {
      id: 3,
      name: "The Family Heritage Connection",
      culture: "Indonesian",
      period: "2023",
      image: decoration3,
      description:
        "A touching story of a family reconnecting with their heritage through traditional artifacts",
      significance: "Cultural preservation",
      materials: "Family heirlooms",
      testimonial:
        "Finding these pieces helped us connect with our grandmother's stories and heritage.",
      client: "The Suharto Family",
      date: "October 2023",
    },
    {
      id: 4,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration4,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 5,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration5,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 6,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration6,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 7,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration7,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 8,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration8,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 9,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration9,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 10,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration10,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 11,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration11,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 12,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration12,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 13,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration13,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 14,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration15,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 15,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration16,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
    {
      id: 16,
      name: "The Artist's Inspiration",
      culture: "Mixed",
      period: "2024",
      image: decoration17,
      description:
        "How our collection inspired a contemporary artist's new exhibition",
      significance: "Artistic inspiration",
      materials: "Various inspirational pieces",
      testimonial:
        "The diversity and authenticity of the collection sparked a whole new direction in my work.",
      client: "Maria Gonzalez",
      date: "January 2024",
    },
  ];

  return (
    <>
      <SEOMetadata
        title="African Decorative Artifacts - Cultural Home Decor"
        description="Transform your space with our curated collection of African decorative artifacts. Each piece blends traditional craftsmanship with contemporary design, bringing authentic cultural stories into your home."
        keywords="African home decor, cultural artifacts, decorative art, traditional African design, ethnic home accessories, cultural interior design, sagana bobex, bobex, curio shop, curio, bobex fine art"
        canonicalUrl="https://bobexcurio.com/decorations"
        ogImage="/og-decorations-image.jpg"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white dark:bg-secondary-dark p-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-primary dark:text-white">
              Beautiful Decorations & Artifacts
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Explore our collection of beautiful decorations and artifacts.
            </p>
          </motion.div>

          <ItemGallery items={clientStories} />
        </div>
      </motion.div>
    </>
  );
};
