import React from "react";
import { motion } from "framer-motion";
import ItemGallery from "../components/item/ItemGallery";
import batik1 from "../assert/batiks/batik1.jpg";
import batik2 from "../assert/batiks/batik2.jpg";
import batik3 from "../assert/batiks/batik3.jpg";
import batik4 from "../assert/batiks/batik4.jpg";
import batik5 from "../assert/batiks/batik5.jpg";
import batik6 from "../assert/batiks/batik6.jpg";
import batik7 from "../assert/batiks/batik7.jpg";
import batik8 from "../assert/batiks/batik8.jpg";
import batik9 from "../assert/batiks/batik9.jpg";
import SEOMetadata from "../components/SEOHelment";

export const BatikPage = () => {
  const batiks = [
    {
      id: 1,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik1,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 2,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik2,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 3,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik3,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 4,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik4,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 5,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik5,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 6,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik6,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 7,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik7,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 8,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik8,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
    {
      id: 9,
      name: "Traditional African Batiks",
      culture: "African",
      period: "Contemporary",
      image: batik9,
      description:
        "Vibrant hand-dyed batik textile featuring bold, geometric patterns inspired by African cultural symbols, nature, and everyday life.",
      materials: "Natural dyes, Cotton, Raffia, Mud cloth",
      significance:
        "Holds deep cultural meaning, used for ceremonial garments, household textiles, and personal adornment",
      technique: "Wax-resist dyeing, Stenciling, Tie-dye",
      dimensions: "2m x 1.5m",
      condition: "New",
      authentication: "Certified by African Textile Association",
    },
  ];

  return (
    <>
      <SEOMetadata
        title="African Batik Art - Colorful Cultural Expressions"
        description="Immerse yourself in the vibrant world of African batik art. Each piece showcases the intricate wax-resist dyeing technique, representing centuries of cultural creativity and artistic innovation."
        keywords="African batik, wax-resist art, traditional textile design, cultural fabric art, hand-painted textiles, African color traditions, curio shop, sagana, bobex, bobex fine art, curio"
        canonicalUrl="https://bobexcurio.com/categories/batiks"
        ogImage="/og-batik-image.jpg"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white dark:bg-secondary-dark p-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-primary dark:text-white">
              Traditional & Contemporary Batik
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Discover the ancient art of wax-resist dyeing, featuring both
              traditional and modern designs
            </p>
          </motion.div>

          <ItemGallery items={batiks} />
        </div>
      </motion.div>
    </>
  );
};
