import React from "react";
import { motion } from "framer-motion";
import ImageSlider from "../common/ImageSlider";
import heroImage from "../../assert/decorations/decorations8.jpg";
import heroImage0 from "../../assert/sculptures/sculp8.jpg";
import heroImage1 from "../../assert/arts/art9.jpg";
import heroImage2 from "../../assert/sculptures/sculp15.jpg";
import heroImage4 from "../../assert/sculptures/sculp3.jpg";
import { useDarkModeContext } from "../../context/DarkModeContext";
const Hero = () => {
  const images = [heroImage0, heroImage1, heroImage2, heroImage, heroImage4];
  const { isDarkMode } = useDarkModeContext();
  return (
    <div className="relative h-[70vh] overflow-hidden">
      <ImageSlider images={images} />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <motion.div
          className="text-center text-white"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <h2 className="text-5xl font-bold mb-4">
            Discover African Treasures
          </h2>
          <p className="text-xl mb-8">
            Explore our unique collection of Kenyan artifacts
          </p>
          <motion.button
            className={` text-white ${isDarkMode ? "bg-gray-900" : "bg-[#8B4513]"} px-8 py-3 rounded-full font-semibold hover:bg-opacity-90 transition-colors`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Welcome
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
