import { useRef } from "react";

export const useImageZoom = (scale = 1.5) => {
  const zoomRef = useRef(null);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    zoomRef.current.style.transformOrigin = `${x}% ${y}%`;
    zoomRef.current.style.transform = `scale(${scale})`;
  };

  const handleMouseLeave = () => {
    zoomRef.current.style.transformOrigin = "center center";
    zoomRef.current.style.transform = "scale(1)";
  };

  return { zoomRef, handleMouseMove, handleMouseLeave };
};
