import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import ItemDetails from "../components/item/ItemDetails";
import RelatedItems from "../components/item/RelatedItems";
import CulturalContext from "../components/item/CulturalContext";
import { useDarkModeContext } from "../context/DarkModeContext";
import spear1 from "../assert/spears/arrow1.jpg";
import spear2 from "../assert/spears/arrow2.jpg";
import spear3 from "../assert/spears/shield1.jpg";
import spear4 from "../assert/spears/shield2.jpg";
import spear5 from "../assert/spears/spear1.png";

import beadednecklace1 from "../assert/necklace/necklace1.jpg";
import beadednecklace2 from "../assert/necklace/necklace2.jpg";
import beadednecklace3 from "../assert/necklace/necklace3.jpg";
import beadednecklace4 from "../assert/necklace/jewelry14.jpg";
import beadednecklace5 from "../assert/necklace/jewelry15.jpg";

import overview1 from "../assert/overviews/overview1.jpg";
import overview2 from "../assert/overviews/overview2.jpg";
import overview3 from "../assert/overviews/overview3.jpg";
import overview4 from "../assert/overviews/overview4.jpg";
import overview5 from "../assert/overviews/overview5.jpg";
import overview6 from "../assert/overviews/overview6.jpg";
import SEOMetadata from "../components/SEOHelment";

// Mock function for related items
const relatedItems = [
  {
    id: 4,
    name: "Maasai Spear",
    image: spear5,
    images: [spear5, spear1, spear2, spear3, spear4],
    culturalValue: "Very High",
    timeFrame: "19th Century",
    description:
      "Traditional Maasai warrior spear, expertly forged using ancestral techniques.",
    origin: "Kenya, Maasai Mara region",
    materials: ["Iron", "Hardwood", "Leather binding"],
    culturalContext:
      "The Maasai spear is a symbol of warrior status and manhood in Maasai culture. These spears were essential tools for both protection and lion hunting during traditional rites of passage. Each spear is carefully crafted by skilled blacksmiths who inherit their knowledge through generations. The length, design, and ornamentation of the spear can indicate the warrior's rank and achievements within the community.",
  },
  {
    id: 5,
    name: "Beaded Necklace",
    image: beadednecklace1,
    images: [
      beadednecklace1,
      beadednecklace2,
      beadednecklace3,
      beadednecklace4,
      beadednecklace5,
    ],
    culturalValue: "High",
    timeFrame: "Early 20th Century",
    description:
      "Elaborate ceremonial beaded necklace featuring traditional Maasai patterns and symbolism.",
    origin: "Kenya, Kajiado region",
    materials: ["Glass beads", "Leather cord", "Metal clasps"],
    culturalContext:
      "Maasai beadwork is a sophisticated art form where colors and patterns carry deep cultural significance. Each necklace tells a story through its design, with different colors representing aspects of Maasai life: red for bravery and blood, blue for sky and water, green for land and growth. These necklaces are worn during important ceremonies and serve as markers of age, marital status, and social position.",
  },
  {
    id: 6,
    name: "Contemporary Kikuyu Sculpture",
    image: overview1,
    images: [overview1, overview2, overview3, overview4, overview5, overview6],
    culturalValue: "High",
    timeFrame: "Mid 20th Century",
    description:
      "Expertly crafted contemporary Kikuyu sculpture, featuring intricate geometric patterns inspired by traditional designs.",
    origin: "Kenya, Mount Kenya region",
    materials: ["Mugumo wood", "Natural oils", "Traditional carving tools"],
    culturalContext:
      "While rooted in Kikuyu cultural heritage, these modern sculptures reinterpret classic motifs and forms. Contemporary Kikuyu artists skillfully blend time-honored techniques with innovative approaches to create sculptural works that resonate with both traditional and contemporary audiences. These sculptures serve as dynamic cultural ambassadors, sharing the rich artistic legacy of the Kikuyu people with the world.",
  },
];

const ItemPage = () => {
  const location = useLocation();
  const item = location.state?.item;
  const { isDarkMode } = useDarkModeContext();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!item) {
    return (
      <div className="flex justify-center items-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
        />
      </div>
    );
  }

  return (
    <>
      <SEOMetadata
        title={`${item.name} - Authentic African Artifact- Curio shop bobex fine art sagana`}
        description={`Explore this exquisite ${item.name} piece, a testament to African craftsmanship and cultural heritage. Handcrafted with traditional techniques, this artifact tells a unique story.`}
        keywords={`African ${item.name}, cultural artifact, handcrafted art, traditional ${item.name}, African craftsmanship`}
        canonicalUrl={`https://bobexcurio.com/item/${item.id}`}
        ogImage={item.images[0]}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={`min-h-screen ${isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"}`}
      >
        <div className="container mx-auto px-4 py-8">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ItemDetails item={item} />
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <CulturalContext context={item.culturalContext} />
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <RelatedItems
              items={relatedItems}
              handleScrollToTop={handleScrollToTop}
            />
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default ItemPage;
