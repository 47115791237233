import React from "react";
import { motion } from "framer-motion";
import ItemGallery from "../components/item/ItemGallery";
import mask1 from "../assert/marks/mask1.jpg";
import mask2 from "../assert/marks/mask2.jpg";
import mask3 from "../assert/marks/mask3.jpg";
import mask4 from "../assert/marks/mask4.jpg";
import mask5 from "../assert/marks/mask5.jpg";
import mask6 from "../assert/marks/mask6.jpg";
import mask7 from "../assert/marks/mask7.jpg";
import mask8 from "../assert/marks/mask8.jpg";
import mask9 from "../assert/marks/mask9.jpg";
import SEOMetadata from "../components/SEOHelment";
export const MaskPage = () => {
  const masks = [
    {
      id: 1,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask1,
      description: "Ceremonial mask used in traditional rituals",
      materials: "Wood, pigments",
      significance: "Protection and spiritual guidance",
    },
    {
      id: 2,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask2,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 3,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask3,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 4,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask4,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 5,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask5,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 6,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask6,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 7,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask7,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 8,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask8,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
    {
      id: 9,
      name: "Venetian Carnival Mask",
      culture: "African",
      period: "Contemporary",
      image: mask9,
      description: "Handcrafted decorative mask",
      materials: "Ceramic, gold leaf",
      significance: "Festival and celebration",
    },
  ];

  return (
    <>
      <SEOMetadata
        title="African Masks - Cultural Artifacts Collection"
        description="Discover our curated collection of authentic African masks. Each piece represents a unique cultural narrative, crafted with traditional techniques and artistic excellence."
        keywords="African masks, tribal masks, ceremonial masks, cultural artifacts, traditional African art, curio shop sagana, bobex fine art, masks"
        canonicalUrl="https://bobexcurio.com/categories/masks"
        ogImage="/og-masks-image.jpg"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white dark:bg-secondary-dark p-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-primary dark:text-white">
              Ceremonial & Decorative Masks
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Discover the mystical world of traditional masks, each telling its
              own unique story
            </p>
          </motion.div>
          <ItemGallery items={masks} />
        </div>
      </motion.div>
    </>
  );
};
